// src/data/coaches.js
const coaches = [
    {
        id: 'eric-elliot',
        name: 'Eric Elliot',
        photo: '/images/coaches/eric.jpg',
        bio: `Eric Elliot is a seasoned PGA professional with 25 years of experience, having started teaching golf in 1999.
              Over the years, he has found great joy in helping golfers improve their skills and achieve their dreams of hitting the perfect shot.
              Outside of his professional life, Eric has a deep passion for sports, particularly Australian Rules Football, and he also enjoys music.
              In fact, he has written around 40 songs, showcasing his creative side.
              When it comes to golf courses, Eric treasures the Royal Melbourne and Kingston Heath, both of which are his favourites.
              He also has a special fondness for the 8th hole at Yering Meadows, which he describes as a great hole.
              Eric's motivation as a golf teaching professional comes from his love and passion for the game. He holds PGA membership as his special certification
              in golf instruction, underscoring his dedication to the profession.
              One of the best pieces of advice he has received in his career is to learn from both personal experiences and others by keeping an open mind.
              This philosophy has undoubtedly contributed to his success and longevity in the field.`,
        rates: {
            adult: [
                { duration: '30 minutes', rate: '$80' },
                { duration: '60 minutes', rate: '$140' },
                { duration: 'On Course', rate: '$220' }
            ],
            junior: [
                { duration: '30 minutes', rate: '$66' },
                { duration: '60 minutes', rate: '$120' },
                { duration: 'On Course', rate: '$200' }
            ]
        }
    },
    // Add more coaches here...
];

export default coaches;
