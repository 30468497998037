import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import about1 from '../assets/images/aboutus/aboutus1.png';
import about2 from '../assets/images/aboutus/aboutus1.jpg';
import history2003 from '../assets/images/History/history2003.png'
import history2006 from '../assets/images/History/history2006.png'
import history2007 from '../assets/images/History/history2007.png'
import history2008 from '../assets/images/History/history2008.png'
import history2020 from '../assets/images/History/history2020.png'
import history2023 from '../assets/images/History/history2023.png'
import './AboutUs.css';
import ParallaxText from '../components/ParallaxText';
import TimelineEvent from '../components/TimelineEvent';

const historyEvents = [
  {
    year: 2023,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: history2023, // 替换为实际的图片路径
  },
  {
    year: 2020,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: history2020, // 替换为实际的图片路径
  },
  {
    year: 2008,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: history2008, // 替换为实际的图片路径
  },
  {
    year: 2007,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: history2007, // 替换为实际的图片路径
  },
  {
    year: 2006,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: history2006, // 替换为实际的图片路径
  },
  {
    year: 2003,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: history2003, // 替换为实际的图片路径
  },
];

// Function to get horizontal variants
const getHorizontalVariants = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 375) {
    return { hidden: { x: -400, opacity: 0 }, visible: { x: -100, opacity: 1 } };
  } else if (screenWidth <= 912) {
    return { hidden: { x: -400, opacity: 0 }, visible: { x: -20, opacity: 1 } };
  } else {
    return { hidden: { x: -400, opacity: 0 }, visible: { x: 0, opacity: 1 } };
  }
};

// Function to get vertical variants
const getVerticalVariants = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 375) {
    return { hidden: { y: -400, opacity: 0 }, visible: { y: -50, opacity: 1 } };
  } else if (screenWidth <= 912) {
    return { hidden: { y: -400, opacity: 0 }, visible: { y: -100, opacity: 1 } };
  } else {
    return { hidden: { y: -400, opacity: 0 }, visible: { y: 0, opacity: 1 } };
  }
};

const textVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { duration: 3, ease: "easeOut" }
  }
};

export default function AboutUs() {
  const [horizontalVariants, setHorizontalVariants] = useState(getHorizontalVariants());
  const [verticalVariants, setVerticalVariants] = useState(getVerticalVariants());

  useEffect(() => {
    const handleResize = () => {
      setHorizontalVariants(getHorizontalVariants());
      setVerticalVariants(getVerticalVariants());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="about-us-intro">
        <div className="about-us-gallery">
          <div className='parallax-text'><ParallaxText>About Us</ParallaxText></div>
          <motion.img 
            src={about1} 
            alt="Golf Club View" 
            className="about-us-image"
            variants={horizontalVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }} 
          />
          <motion.img 
            src={about2} 
            alt="Golf Club Landscape" 
            className="about-us-image1"
            variants={verticalVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }} 
          />
        </div>
        <div className="about-us-overlay">
          <motion.div 
            className="about-us-text" 
            initial="hidden"
            animate="visible"
            variants={textVariants}
          >
            <h1>Yering Meadows Golf Club is nestled in one of the world's most picturesque natural settings in the Yarra Valley.</h1>
            <p>Against the stunning backdrop of the Dandenong Ranges, the course winds through the rolling landscapes, offering a unique and breathtaking view from virtually every hole.</p>
          </motion.div>
        </div>
      </div>
      <div className="timeline-container">
        <h1>Historical Timeline</h1>
        {historyEvents.map((event, index) => (
          <TimelineEvent
            key={index}
            year={event.year}
            content={event.content}
            image={event.image}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}

/*
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import about1 from '../assets/images/aboutus/aboutus1.png';
import about2 from '../assets/images/aboutus/aboutus2.png';
import './AboutUs.css';
import ParallaxText from '../components/ParallaxText';
import TimelineEvent from '../components/TimelineEvent';

const historyEvents = [
  {
    year: 2008,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: about1, // 替换为实际的图片路径
  },
  {
    year: 1999,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: about2, // 替换为实际的图片路径
  },
  {
    year: 2008,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: about1, // 替换为实际的图片路径
  },
  {
    year: 1999,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: about2, // 替换为实际的图片路径
  },
  {
    year: 2008,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: about1, // 替换为实际的图片路径
  },
  {
    year: 1999,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: about2, // 替换为实际的图片路径
  },
  {
    year: 2008,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: about1, // 替换为实际的图片路径
  },
  {
    year: 1999,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: about2, // 替换为实际的图片路径
  },
];

// Function to get horizontal variants
const getHorizontalVariants = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 375) {
    return { hidden: { x: -400, opacity: 0 }, visible: { x: -130, opacity: 1 } };
  } else if (screenWidth <= 912) {
    return { hidden: { x: -400, opacity: 0 }, visible: { x: -100, opacity: 1 } };
  } else {
    return { hidden: { x: -400, opacity: 0 }, visible: { x: 0, opacity: 1 } };
  }
};

// Function to get vertical variants
const getVerticalVariants = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 375) {
    return { hidden: { y: -400, opacity: 0 }, visible: { y: -50, opacity: 1 } };
  } else if (screenWidth <= 912) {
    return { hidden: { y: -400, opacity: 0 }, visible: { y: -100, opacity: 1 } };
  } else {
    return { hidden: { y: -400, opacity: 0 }, visible: { y: 0, opacity: 1 } };
  }
};

export default function AboutUs() {
  const [horizontalVariants, setHorizontalVariants] = useState(getHorizontalVariants());
  const [verticalVariants, setVerticalVariants] = useState(getVerticalVariants());

  useEffect(() => {
    const handleResize = () => {
      setHorizontalVariants(getHorizontalVariants());
      setVerticalVariants(getVerticalVariants());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="about-us-intro">
        <div className="about-us-gallery">
          <div className='parallax-text'><ParallaxText>About Us</ParallaxText></div>
          <motion.img 
            src={about1} 
            alt="Golf Club View" 
            className="about-us-image"
            variants={horizontalVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }} 
          />
          <motion.img 
            src={about2} 
            alt="Golf Club Landscape" 
            className="about-us-image1"
            variants={verticalVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }} 
          />
        </div>
        <div className="about-us-overlay">
          <motion.div 
            className="about-us-text" 
            initial="hidden"
            animate="visible"
            variants={horizontalVariants} // Choose horizontal or vertical based on your design preference
          >
            <h1>ROYAL COUNTY DOWN IS LOCATED IN ONE OF THE WORLD'S MOST NATURALLY BEAUTIFUL LINKS SETTINGS IN THE MURLOUGH NATURE RESERVE.</h1>
            <p>Against the magnificent backdrop of the Mountains of Mourne, the links stretches along the shores of Dundrum Bay, zigzagging back and forth to provide a different vista from virtually every hole.</p>
          </motion.div>
        </div>
      </div>
      <div className="timeline-container">
        <h1>Historical Timeline</h1>
        {historyEvents.map((event, index) => (
          <TimelineEvent
            key={index}
            year={event.year}
            content={event.content}
            image={event.image}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}
*/



/*
import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import about1 from '../assets/images/aboutus/aboutus1.png'
import about2 from '../assets/images/aboutus/aboutus2.png'
import history2003 from '../assets/images/History/history2003.png'
import history2006 from '../assets/images/History/history2006.png'
import history2007 from '../assets/images/History/history2007.png'
import history2008 from '../assets/images/History/history2008.png'
import history2020 from '../assets/images/History/history2020.png'
import history2023 from '../assets/images/History/history2023.png'
import './AboutUs.css';
import ParallaxText from '../components/ParallaxText';
import TimelineEvent from '../components/TimelineEvent';
  
const historyEvents = [
  {
    year: 2023,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: history2023, // 替换为实际的图片路径
  },
  {
    year: 2020,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: history2020, // 替换为实际的图片路径
  },
  {
    year: 2008,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: history2008, // 替换为实际的图片路径
  },
  {
    year: 2007,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: history2007, // 替换为实际的图片路径
  },
  {
    year: 2006,
    content: "The club won the Division 4 Pennant, marking a recent highlight in its successful history.",
    image: history2006, // 替换为实际的图片路径
  },
  {
    year: 2003,
    content: "Aaron Baddeley, having joined the club as a child, won the Australian Open Championship at fourteen and became a life member in 2000.",
    image: history2003, // 替换为实际的图片路径
  },
];


const imageVariantsleft = {
    hidden: {
      x: -400, 
      opacity: 0
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 10
      }
    }
  };

const imageVariantstop = {
    hidden: {
      y: -400, 
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 10
      }
    }
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 3, ease: "easeOut" }
    }
  };

export default function AboutUs() {

  useEffect(() => {
    const header = document.querySelector('.header-container');
    const content = document.querySelector('.about-us-container');
      if (header && content) {
        const headerHeight = header.offsetHeight;
        content.style.paddingTop = `${headerHeight}px`;
      }
  }, []);
     
  return (
    <div>
      <Header />
      <div className="about-us-intro" >
        <div className="about-us-gallery">
        <div className='parallax-text'><ParallaxText>About Us</ParallaxText></div>
            <motion.img 
            src={about1} 
            alt="Golf Club View" 
            className="about-us-image"
            variants={imageVariantsleft}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }} 
            />
            <motion.img 
            src={about2} 
            alt="Golf Club Landscape" 
            className="about-us-image1"
            variants={imageVariantstop}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }} 
            />
        </div>
        <div className="about-us-overlay">
          <motion.div 
              className="about-us-text" 
              initial="hidden"
              animate="visible"
              variants={textVariants}
            >
            <h1>ROYAL COUNTY DOWN IS LOCATED IN ONE OF THE WORLD'S MOST NATURALLY BEAUTIFUL LINKS SETTINGS IN THE MURLOUGH NATURE RESERVE.</h1>
            <p>Against the magnificent backdrop of the Mountains of Mourne, the links stretches along the shores of Dundrum Bay, zigzagging back and forth to provide a different vista from virtually every hole.</p>
          </motion.div>
        </div>
      </div>
        <div className="timeline-container">
          <h1>Historical Timeline</h1>
        {historyEvents.map((event, index) => (
          <TimelineEvent
            key={index}
            year={event.year}
            content={event.content}
            image={event.image}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}
*/