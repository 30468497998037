import React from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './DressCode.css';
import image1 from '../assets/images/dresscode/DressCode1.jpeg';

const DressCode = () => {
    return (
        <div>
            <Header />
            <div className="dress-code-container">
                <div className="DC-title-container">
                    <p className="dresscodetitle">Dress Standards</p>
                    <p className="dresscodetitle-subtitle">Players are expected to wear an acceptable Standard of golfing attire when playing the YeringMeadows Golf Course.</p>
                </div>
                <div className="divider"></div>
                <div className="section">
                    <div className="men">
                        <p>FOR MEN</p>
                        <p1>To maintain our standards, the following items are deemed non-acceptable attire at the club:</p1>
                        <ul>
                            <li>• Football Shorts</li>
                            <li>• Singlets</li>
                            <li>• Thongs(Filp-Flops)</li>
                            <li>• Construction/Work Attire</li>
                            <li>• Hi-Vis Clothing</li>
                            <li>• Work Boots</li>
                        </ul>
                        <p2 className="note">NOTE: Knee-length or sports socks should be worn with tailored shorts. In the interest of safety, golfers are advised to wear golf shoes.</p2>
                    </div>
                    <div className="women">
                        <p>FOR WOMEN</p>
                        <p1>To maintain our standards, the following items are deemed non-acceptable attire at the club:</p1>
                        <ul>
                            <li>• Skirt</li>
                            <li>• Singlets</li>
                            <li>• Thongs(Filp-Flops)</li>
                            <li>• Construction/Work Attire</li>
                            <li>• Hi-Vis Clothing</li>
                            <li>• Work Boots</li>
                        </ul>
                        <p2 className="note">NOTE: In the interest of safety, golfers are advised to wear golf shoes.</p2>
                    </div>
                </div>
                <div className="divider2"></div>
                <div className="image-container">
                    <img src={image1} alt="Dress Code" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DressCode;
/*
import React from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './DressCode.css';
import image1 from '../assets/images/dresscode/DressCode1.jpeg';

const DressCode = () => {
    return (
        <div>
            <Header />
            <div className="dress-code-container">
                <p className="dresscodetitle">Dress Standards</p>
                <p className="dresscodetitle-subtitle">Players are expected to wear an acceptable Standard of golfing attire when playing the YeringMeadows Golf Course.</p>
                <div className="divider"></div>
                <div className="section">
                    <div className="men">
                        <p>FOR MEN</p>
                        <p1>To maintain our standards, the following items are deemed non-acceptable attire at the club:</p1>
                        <ul>
                            <li>• Football Shorts</li>
                            <li>• Singlets</li>
                            <li>• Thongs(Filp-Flops)</li>
                            <li>• Construction/Work Attire</li>
                            <li>• Hi-Vis Clothing</li>
                            <li>• Work Boots</li>
                        </ul>
                        <p2 className="note">NOTE: Knee-length or sports socks should be worn with tailored shorts. In the interest of safety, golfers are advised to wear golf shoes.</p2>
                    </div>
                    <div className="women">
                        <p>FOR WOMEN</p>
                        <p1>To maintain our standards, the following items are deemed non-acceptable attire at the club:</p1>
                        <ul>
                            <li>• Skirt</li>
                            <li>• Singlets</li>
                            <li>• Thongs(Filp-Flops)</li>
                            <li>• Construction/Work Attire</li>
                            <li>• Hi-Vis Clothing</li>
                            <li>• Work Boots</li>
                        </ul>
                        <p2 className="note">NOTE: In the interest of safety, golfers are advised to wear golf shoes.</p2>
                    </div>
                </div>
                <div className="divider2"></div>
                <div className="image-container">
                    <img src={image1} alt="Dress Code" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DressCode;
*/