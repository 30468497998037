import React from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './Corporate.css'; 
import { ContactUs } from '../components/CorporateForm';
import img1 from '../assets/images/Corporate/Cp4.jpg';
import img2 from '../assets/images/Corporate/Cp5.jpg';
import img3 from '../assets/images/Corporate/Cp6.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

const Corporate = () => {

  const slides = [
    { 
        image: img1, 
    },
    { 
        image: img2, 
    },
    { 
        image: img3, 
    },
  ];

  return (
    <div>
        <Header />
        <div className='corporate-booking-container'>
            <div className='corporate-booking-image'>
                {/* Swiper gallery */}
                <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation
                    autoplay={{ delay: 2500 }}
                    spaceBetween={50}
                    slidesPerView={1}
                >
                  {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <img src={slide.image} alt={`Slide ${index}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            <div className='corporate-booking-form'>
              <ContactUs/>
            </div>  
        </div>
        <Footer />
    </div>
  );
};

export default Corporate;
