import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SportsGolfIcon from '@mui/icons-material/SportsGolf';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TemporaryDrawer from '../components/sidebar';
import './Header.css';
import logo from '../assets/images/logo/logo.png';

export default function Header() {
  const [isShrinked, setIsShrinked] = useState(false);
  const [headerHeight, setHeaderHeight] = useState('140px'); // Initial header height
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    const checkHeaderHeight = () => {
      const width = window.innerWidth;
      if (width > 768) {
        // Larger screens
        setHeaderHeight(isShrinked ? '80px' : '120px');
      } else if (width > 600 && width <= 768) {
        // Screens between 600px and 768px
        setHeaderHeight(isShrinked ? '80px' : '120px');
      } else {
        // Screens at or below 600px
        setHeaderHeight(isShrinked ? '80px' : '70px');
      }
    };

    const handleScroll = () => {
      const shouldShrink = window.scrollY > 50;
      setIsShrinked(shouldShrink);
      checkHeaderHeight();
    };

    const handleResize = () => {
      checkHeaderHeight();
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Initialize the values on component mount
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isShrinked]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <div className={`header-container ${isShrinked ? 'header-shrink' : 'header-large'}`}>
      <button className="navbar-interface">
        <Tooltip title="Menu">
          <IconButton
            color='primary'
            size="small"
            sx={{ ml: 2 }}
            aria-controls="menu-list-grow"
            aria-haspopup="true"
            onClick={toggleDrawer('left', true)}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </button>
      
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        id="menu-list-grow"
      >
        <MenuItem onClick={() => setMenuAnchorEl(null)}>Option 1</MenuItem>
        <MenuItem onClick={() => setMenuAnchorEl(null)}>Option 2</MenuItem>
        <MenuItem onClick={() => setMenuAnchorEl(null)}>Option 3</MenuItem>
      </Menu>

      <img src={logo} alt="Logo" className="logo" />

        <button className="member-login-interface">
            <Tooltip title="Members Login">
                <IconButton
                    color='primary'
                    size="small"
                    sx={{ ml: 2 }}
                    component="a"
                    href="https://www.yeringmeadows.com.au/security/login.msp"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <AccountCircleIcon />
                </IconButton>
            </Tooltip>
        </button>

        <button className="booking-interface">
            <Tooltip title="Book A Tee Time">
                <IconButton
                    color='primary'
                    size="small"
                    sx={{ ml: 2 }}
                    component="a"
                    href="https://www.yeringmeadows.com.au/guests/bookings/ViewPublicCalendar.msp?booking_resource_id=3000000"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <SportsGolfIcon />
                </IconButton>
            </Tooltip>
        </button>

      <TemporaryDrawer 
        state={state} 
        setState={setState}
        headerHeight={headerHeight} // Pass the dynamic header height
        toggleDrawer={toggleDrawer}
      />
    </div>
  );
}
