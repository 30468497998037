import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Button, Icon } from '@mui/material';
import { ArrowBack, ArrowForward, Home } from '@mui/icons-material';
import './HolePage.css';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const holeDetails = {
    1: {
        description: "1st Hole – Elevated tees provide an inviting opening hole. With a hazard right and bunker left a well positioned tee shot allows an easier approach to a small two-tier green.",
        images: [
        "/images/holes/first-hole-1.jpg",
        "/images/holes/first-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 361, par: 4 },
            Blue: { metres: 342, par: 4 },
            Red: { metres: 310, par: 4 }
        }
    },
    2: {
        description: "Fairway bunkers guard the left side of this fairway. A precise approach from the fairway is a necessity to this elevated green. Advanced golfers may attack but safety is to the front left portion of the green.",
        images: [
        "/images/holes/second-hole-1.jpg",
        "/images/holes/second-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 367, par: 4 },
            Blue: { metres: 357, par: 4 },
            Red: { metres: 331, par: 4 }
        }
    },
    3: {
        description: "Accuracy and the correct club selection makes this a strong par three. With a large putting surface a generous run-up to the middle/left of this green provides some relief from the right hazard and bunkers that surround.",
        images: [
        "/images/holes/third-hole-1.jpg",
        "/images/holes/third-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 197, par: 3 },
            Blue: { metres: 180, par: 3 },
            Red: { metres: 160, par: 3 }
        }
    },
    4: {
        description: "A Deceptive hole with course knowledge making this a real birdie chance. Position safe to the left of the large fairway bunker or longer hitter straight over at the green. The small fast sloping green is tilted from left to right.",
        images: [
        "/images/holes/fourth-hole-1.jpg",
        "/images/holes/fourth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 327, par: 4 },
            Blue: { metres: 304, par: 4 },
            Red: { metres: 260, par: 4 }
        }
    },
    5: {
        description: "The first of the great par 5s on this course. All emphasis is placed on hitting this fairway. With a hazard left and a bunker right, place your drive in the fairway and second shot within short iron distance to the green. Club selection needs to be precise with approach to this deep green.",
        images: [
        "/images/holes/fifth-hole-1.jpg",
        "/images/holes/fifth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 515, par: 5 },
            Blue: { metres: 496, par: 5 },
            Red: { metres: 433, par: 5 }
        }
    },
    6: {
        description: "A long par four with plenty of options. Drive short of the bunker in the middle of the fairway or take it on. Right side of this fairway provides the biggest advantage. Shorter hitters watch your second shot as water weaves in front of the green.",
        images: [
        "/images/holes/sixth-hole-1.jpg",
        "/images/holes/sixth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 413, par: 4 },
            Blue: { metres: 394, par: 4 },
            Red: { metres: 373, par: 5 }
        }
    },
    7: {
        description: "Simple in theory but tough to execute! This short par 3 sets a tricky challenge for all level of golfers. Favour the front of this green with a sever drop off long.",
        images: [
        "/images/holes/seventh-hole-1.jpg",
        "/images/holes/seventh-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 133, par: 3 },
            Blue: { metres: 123, par: 3 },
            Red: { metres: 109, par: 3 }
        }
    },
    8: {
        description: "The hole that all golfers talk about. Hit Tee shot straight at the three bunkers in the distance, longer hitters just left. Work out how far you want for your third or become a hero and fire your second at the green. There is more room left of this green than it looks.",
        images: [
        "/images/holes/eighth-hole-1.jpg",
        "/images/holes/eighth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 497, par: 5 },
            Blue: { metres: 481, par: 5 },
            Red: { metres: 426, par: 5 }
        }
    },
    9: {
        description: "A strong hole with par being a good score. Tee shot to the right side of the fairway will leave a long up hill shot to a deep two-tier putting surface. Avoid the two bunkers right of this green and two putts to finish this hole is a good result.",
        images: [
        "/images/holes/ninth-hole-1.jpg",
        "/images/holes/ninth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 409, par: 4 },
            Blue: { metres: 384, par: 4 },
            Red: { metres: 325, par: 4 }
        }
    },
    10: {
        description: "Precision is a necessity on this visually spectacular par 5. A tough driving and lay-up hole with a hazard down the right side. Room right of this green provides some relief and a definite birdie chance if you stay dry!",
        images: [
        "/images/holes/tenth-hole-1.jpg",
        "/images/holes/tenth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 504, par: 5 },
            Blue: { metres: 492, par: 5 },
            Red: { metres: 432, par: 5 }
        }
    },
    11: {
        description: "This hole will test all level of golfers. The longer you hit, the tighter this fairway becomes. A tough second to this small sloping left to right green makes for a great risk reward hole.",
        images: [
        "/images/holes/eleventh-hole-1.jpg",
        "/images/holes/eleventh-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 391, par: 4 },
            Blue: { metres: 369, par: 4 },
            Red: { metres: 319, par: 4 }
        }
    },
    12: {
        description: "With a green surrounded by bunkers a high flighted shot is required. The subtle tier in this green provides a great putting surface for making a birdie or saving your par. You will be tested from this point in so enjoy this short hole.",
        images: [
        "/images/holes/twelfth-hole-1.jpg",
        "/images/holes/twelfth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 169, par: 3 },
            Blue: { metres: 150, par: 3 },
            Red: { metres: 132, par: 3 }
        }
    },
    13: {
        description: "The longest of the par 5s starts with pleasure and ends with torment. Many options are presented with your lay-up and approach to this hole guarded by hazards. Take note of water yardage carries and bunker placements to navigate your way down this hole.",
        images: [
        "/images/holes/thirteenth-hole-1.jpg",
        "/images/holes/thirteenth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 540, par: 5 },
            Blue: { metres: 511, par: 5 },
            Red: { metres: 451, par: 5 }
        }
    },
    14: {
        description: "Visually a very tough short par 4. This fairway is wide before the first of the right side bunkers. Longer hitters have a narrowing landing area. Play it safe or take this hole on and be rewarded. Precision down this entire hole is a must.",
        images: [
        "/images/holes/fourteenth-hole-1.jpg",
        "/images/holes/fourteenth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 361, par: 4 },
            Blue: { metres: 342, par: 4 },
            Red: { metres: 310, par: 4 }
        }
    },
    15: {
        description: "A tempting hole for the longer hitters. Take the driver and fire straight at the green but that’s where the danger is. This semi island green is guarded by water. With many options it’s short but suits the disciplined player.",
        images: [
        "/images/holes/fifteenth-hole-1.jpg",
        "/images/holes/fifteenth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 333, par: 4 },
            Blue: { metres: 325, par: 4 },
            Red: { metres: 266, par: 4 }
        }
    },
    16: {
        description: "Normally playing towards a northerly wind this par 3 can play long with a water carry required to get on the left side of this green. The centre of this green feeds left helping to soften this hole. Lots of room short right provides some relief.",
        images: [
        "/images/holes/sixteenth-hole-1.jpg",
        "/images/holes/sixteenth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 171, par: 3 },
            Blue: { metres: 158, par: 3 },
            Red: { metres: 120, par: 3 }
        }
    },
    17: {
        description: "With no water guarding either side of this fairway the only question needed answering is what line to take with longer hitters over the right side bunker. This deep green requires precision to provide a short birdie chance.",
        images: [
        "/images/holes/seventeenth-hole-1.jpg",
        "/images/holes/seventeenth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 403, par: 4 },
            Blue: { metres: 375, par: 4 },
            Red: { metres: 326, par: 4 }
        }
    },
    18: {
        description: "A tough par 4 requiring distance and accuracy. Avoid both hazard and bunkers with your tee shot and face a mid – long iron up the hill.  This two tier green has a false front right edge but is also a good area leaving a simple approach.",
        images: [
        "/images/holes/eighteenth-hole-1.jpg",
        "/images/holes/eighteenth-hole-2.jpg"
        ],
        tees: {
            Black: { metres: 380, par: 4 },
            Blue: { metres: 360, par: 4 },
            Red: { metres: 304, par: 4 }
        }
    },
    19: {
        description: "This mid length par 4 is best attacked with precision. A long iron or fairway wood to the left side of this fairway is best suited. A large putting surface awaits with bunkers guarding the front right of this green.",
        images: [
        "/images/holes/nineteenth-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 358, par: 4 },
            Blue: { metres: 343, par: 4 },
            Red: { metres: 311, par: 4 }
        }
    },
    20: {
        description: "An elevated green with bunkers and collection areas make this a tough target to hit. Best approach with a short iron or pitch shot. Avoid the scattered fairway bunkers off the tee and a birdie is a real chance.",
        images: [
        "/images/holes/twentieth-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 332, par: 4 },
            Blue: { metres: 315, par: 4 },
            Red: { metres: 286, par: 4 }
        }
    },
    21: {
        description: "With stunning views this down hill par 4 provides a stern test. Drive long and straight towards the right side bunker, this fairway will feed your ball left. The left greenside bunker and collection area in front of this green make for a deceptive approach.",
        images: [
        "/images/holes/twenty-first-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 419, par: 4 },
            Blue: { metres: 399, par: 4 },
            Red: { metres: 350, par: 4 }
        }
    },
    22: {
        description: "All that can be seen is bunkers on this the shortest of the par 3s. This green is rather undulating from back to front. A fantastic short par 3.",
        images: [
        "/images/holes/twenty-second-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 130, par: 3},
            Blue: { metres: 121, par: 3 },
            Red: { metres: 101, par: 3 }
        }
    },
    23: {
        description: "An elevated first half of this hole feeds down to a well guarded lay-up and approach area. Tee shot should be directed towards the right side of the fairway with a severe drop off left. Attack with caution and be rewarded.",
        images: [
        "/images/holes/twenty-third-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 500, par: 5 },
            Blue: { metres: 474, par: 5},
            Red: { metres: 441, par: 5 }
        }
    },
    24: {
        description: "With a hazard right and bunkers left a well positioned tee shot is required to tackle the large putting surface with a short approach shot. This is an inviting birdie chance.",
        images: [
        "/images/holes/twenty-fourth-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 328, par: 4 },
            Blue: { metres: 310, par: 4 },
            Red: { metres: 245, par: 4 }
        }
    },
    25: {
        description: "Precision is needed with a well guarded green of bunkers, collection areas and a right side hazard. This two-tier green that slops towards the hazard provides some assistance in finding a right side pin position.",
        images: [
        "/images/holes/twenty-fifth-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 170, par: 3 },
            Blue: { metres: 151, par: 3 },
            Red: { metres: 130, par: 3 }
        }
    },
    26: {
        description: "With an index of number 1 on this golf course a tough test awaits. This fairway needs to be found with hazard left and bunkers right. Water feeds in front of this sloping back to front green. Par on this hole is a great result.",
        images: [
        "/images/holes/twenty-sixth-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 418, par: 4 },
            Blue: { metres: 406, par: 4 },
            Red: { metres: 371, par: 4 }
        }
    },
    27: {
        description: "This climbing par 5 is covered with scattered bunkers the entire way up the fairway. Avoid these and the best angle of approach is from the right to a fantastic small two tier green.",
        images: [
        "/images/holes/twenty-seventh-hole-1.jpg",
        ],
        tees: {
            Black: { metres: 492, par: 5 },
            Blue: { metres: 486, par: 5 },
            Red: { metres: 486, par: 5 }
        }
    }

};

const HolePage = () => {
  const { holeNumber } = useParams();
  const holeNumberString = holeNumber ? holeNumber.toString() : null;
  const details = holeNumberString ? holeDetails[holeNumberString] : null;
  const [topMargin, setTopMargin] = useState('100px');

  useEffect(() => {
    const updateTopMargin = () => {
      const headerHeight = document.querySelector('.header-container').offsetHeight;
      setTopMargin(`${headerHeight}px`);
    };

    updateTopMargin();
    window.addEventListener('resize', updateTopMargin);

    return () => {
      window.removeEventListener('resize', updateTopMargin);
    };
  }, []);

  return (
    <div>
      <Header />
      <Box className="hole-page-container" sx={{ marginTop: topMargin, padding: '16px' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Hole {holeNumber}
        </Typography>
        {details ? (
          <>
            {details.tees && (
              <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Tee</TableCell>
                      <TableCell>Metres</TableCell>
                      <TableCell>Par</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(details.tees).map((tee) => (
                      <TableRow key={tee}>
                        <TableCell sx={{ color: tee.toLowerCase() }}>{tee}</TableCell>
                        <TableCell>{details.tees[tee].metres}</TableCell>
                        <TableCell>{details.tees[tee].par}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Typography variant="body1" component="p" gutterBottom>
              {details.description}
            </Typography>
            <Box className="images" sx={{ display: 'flex', justifyContent: 'center', gap: '8px', flexWrap: 'wrap' }}>
              {details.images.map((image, index) => (
                <Box
                  component="img"
                  key={index}
                  src={image}
                  alt={`Hole ${holeNumber} - ${index + 1}`}
                  onError={(e) => {
                    console.error(`Error loading image: ${image}`);
                    e.target.src = '/images/placeholder.jpg'; // Set a placeholder image
                  }}
                  sx={{ width: '40%', height: 'auto', borderRadius: '8px' }}
                />
              ))}
            </Box>
          </>
        ) : (
          <Typography variant="body1" component="p">
            No details available for this hole.
          </Typography>
        )}
        <Box className="navigation-links" sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          {holeNumber > 1 && (
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
              <Button component={RouterLink} to={`/hole/${holeNumber - 1}`} startIcon={<ArrowBack />} variant="contained" color="primary">
                Prev
              </Button>
            </motion.div>
          )}
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Button component={RouterLink} to="/golf/golfcourse" startIcon={<Home />} variant="contained" color="secondary">
              Overview
            </Button>
          </motion.div>
          {holeNumber < 27 && (
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
              <Button component={RouterLink} to={`/hole/${parseInt(holeNumber) + 1}`} endIcon={<ArrowForward />} variant="contained" color="primary">
                Next
              </Button>
            </motion.div>
          )}
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default HolePage;