import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#FFFBB3',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#c8b07a',
      },
    },
    typography: {
      // typography settings
    },
    // other theme settings
  });
  
export default theme;
