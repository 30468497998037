import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes';
import HomePage from './views/HomePage';
import AboutUs from './views/AboutUs';
import ClubNews from './views/ClubNews';
import GolfCourse from './views/Golfcourse';
import Golf from './views/Golf';
import Booking from './views/Booking';
import GolfForm from './views/GolfForm';
import VisitorInfo from './views/VisitorInfo';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GolfCourseDetail from './components/GolfCourse';
import PracticeFacilities from './views/PracticeFacilities';
import Corporate from './views/Corporate';
import FunctionEvents from './views/FunctionEvents';
import DressCode from './views/DressCode';
import Membership from './views/Membership';
import HolePage from './pages/HolePage';
import ContactUs from './views/ContactUs';
import BookingLesson from './views/GolfLesson';
import CoachProfile from './components/CoachProfile';
import ScrollToTop from './components/ScrollToTop';
import TagManager from 'react-gtm-module'; // Import GTM module

// Initialize GTM
const tagManagerArgs = {
  gtmId: 'GTM-5282826V'
};
TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/club-news" element={<ClubNews />} />
          <Route path='/golf' element={<Golf />} />
          <Route path='/golf/golfcourse' element={<GolfCourse />} />
          <Route path='/golf/golfForm' element={<GolfForm />} />
          <Route path='/golf/golf-lesson' element={<BookingLesson />} />
          <Route path="/coach/:coachId" element={<CoachProfile />} />
          <Route path="/booking/table-booking" element={<Booking />} />
          <Route path="/booking" element={<VisitorInfo />} />
          <Route path="/corporate" element={<Corporate />} />
          <Route path="/functions-events" element={<FunctionEvents />} />
          <Route path="/practice-facilities" element={<PracticeFacilities />} />
          <Route path="/golf/golfcourse/golfcoursedetail" element={<GolfCourseDetail />} />
          <Route path="/dress-code" element={<DressCode />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/hole/:holeNumber" element={<HolePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
