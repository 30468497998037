import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import sampleimg from '../assets/images/Golf/coursepic.jpg';
import './Golfcourse.css';
import { Link, useLocation } from 'react-router-dom';

const Golfcourse = () => {
    const [topMargin, setTopMargin] = useState('100px');
    const location = useLocation();

    useEffect(() => {
        const updateTopMargin = () => {
            const headerHeight = document.querySelector('.header-container').offsetHeight;
            setTopMargin(`${headerHeight}px`);
        };

        updateTopMargin();
        window.addEventListener('resize', updateTopMargin);

        return () => {
            window.removeEventListener('resize', updateTopMargin);
        };
    }, []);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className="golf-page" style={{ marginTop: topMargin }}>
            <Header />
            <div className="golf-grid">
                <div className="grid-item Nursery">
                    <img src={sampleimg} alt="Nursery Course" />
                </div>
                <div className="grid-item Nursery-course-description" id="nursery">
                    <h2>Nursery course</h2>
                    <p>Holes 1 to 18 on the Nursery Course start near the clubhouse, descending to flatter land. The first nine holes meander through gently rolling terrain, showcasing some of the best layouts. Notable holes include the 327-metre par-4 4th, which is driveable from the forward tees, and the challenging par-5 5th with a dipping fairway and water hazard. The lengthy par-4 6th features a central bunker and a creek before the raised green, demanding precision and strategy.</p>
                    <Link to="/golf/golfcourse/golfcoursedetail#nursery">
                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button"
                        >
                            Tour the Courses
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item Valley-course-description" id="valley">
                    <h2>Valley course</h2>
                    <p>Our courses are arranged into three unique 18-hole configurations: Nursery, Valley, and Homestead. Each course seamlessly integrates into the natural landscape, enhancing the beauty of the countryside. Watson’s thoughtfully placed ponds and wetlands are key architectural features, presenting strategic challenges on nearly every hole in the first 18, with a more subtle presence across holes 19-27.</p>
                    <Link to="/golf/golfcourse/golfcoursedetail#valley">
                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button"
                        >
                            Tour the Courses
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item Valley-course-image">
                    <img src={sampleimg} alt="Valley Course" />
                </div>
                <div className="grid-item Homestead">
                    <img src={sampleimg} alt="Homestead Course" />
                </div>
                <div className="grid-item Homestead-course-description" id="homestead">
                    <h2>Homestead course</h2>
                    <p>The Homestead Course at Yering Meadows adds a unique contrast to the other nines. Starting with straightforward par-4s, it quickly ramps up in difficulty and variety. The 22nd hole, a 130-metre par-3, requires precise shots to avoid sand traps. The par-5 23rd slopes towards a lake, demanding careful positioning. The 26th hole, a 418-metre par-4, is the toughest, with water lining the entire left side and a creek in front of the deep green, testing golfers' skills and patience.</p>
                    <Link to="/golf/golfcourse/golfcoursedetail#homestead">
                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button"
                        >
                            Tour the Courses
                        </motion.button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Golfcourse;
