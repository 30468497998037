import React from 'react';
import './Banner1.css';
import GolfHeaderImage from '../assets/images/VisitorInfo/GolfHeaderImage.jpg';

const ParallaxBanner1 = () => {
    return (
        <div>
            <div className="parallax-banner1 small" style={{ backgroundImage: `url(${GolfHeaderImage})` }}>

            </div>
            <div className="parallax-banner1 medium" style={{ backgroundImage: `url(${GolfHeaderImage})` }}>

            </div>
            <div className="parallax-banner1 large" style={{ backgroundImage: `url(${GolfHeaderImage})` }}>
  
            </div>
        </div>
    );
};

export default ParallaxBanner1;
