// File: Footer.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; 
import FacebookIcon from '@mui/icons-material/Facebook'; 
import InstagramIcon from '@mui/icons-material/Instagram'; 

const TermsModal = ({ show, handleClose }) => {
    return (
        <div className={`modal ${show ? 'show' : ''}`} onClick={handleClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <span className="close" onClick={handleClose}>&times;</span>
                <h4>Terms and Conditions</h4>
                <p>The 25% is calculated off the new category that joins (not the member referring). Once the new member pays their Subscription in full, the credit will be applied to their house account for use in the Clubhouse and Golf Shop.</p>
                <p>$25 Green Fee</p>
                <p>Available once only per person, must be booked via email to YM.Reception@yeringmeadows.com.au and subject to availability.</p>
                <p>$29 per week 6-Day Membership (same conditions as the Elementary Membership)</p>
                <ul>
                    <li>Sunday to Friday playing rights</li>
                    <li>No joining fee</li>
                    <li>Full use of clubhouse facilities</li>
                    <li>Handicap and Golf Link Number</li>
                    <li>Not eligible for Club Board Events</li>
                    <li>All compulsory items due with Annual/First instalment – GVA$90, Personal Insurance $65, Members Association $40. (Members Association—Secondary year fee $20), Administration Fee $50 for setting up payment plan</li>
                    <li>Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</li>
                </ul>
            </div>
        </div>
    );
};

const Footer = () => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">About us</Link></li>
                        <li><Link to="/club-news">Club News</Link></li>
                        <li><Link to="/golf">Golf</Link></li>
                        <li><Link to="https://www.yeringmeadows.com.au/guests/bookings/ViewPublicCalendar.msp?booking_resource_id=3000000">Booking</Link></li>
                        <li><Link to="/membership">Membership</Link></li>
                        <li><Link to="/corporate">Corporate</Link></li>
                        <li><Link to="/functions-events">Function & Events</Link></li>
                        <button className="link-button" onClick={handleOpenModal}>Terms and Conditions</button>
                    </ul>
                </div>
                <div className="contact-divider"></div>
                <div className="contact-section">
                    <h4>Contact</h4>
                    <p>Yering Meadows Golf Club</p>
                    <p>178 - 180 Victoria Road, YERING VIC 3770</p>
                    <p>Email: ym.reception@yeringmeadows.com.au</p>
                    <p>Phone: +61 3 9738 9000</p>
                </div>
            </div>
            <div className="footer-section footer-rights">
                <p>@ 2024 Yering Meadows Golf Club. All rights reserved.</p>
                <div className="social-media">
                    <a href="https://www.facebook.com/YeringMeadows/" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon className="social-media-icon" />
                    </a>
                    <a href="https://instagram.com/yeringmeadows_golfclub" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon className="social-media-icon" />
                    </a>    
                </div>
            </div>
            <TermsModal show={showModal} handleClose={handleCloseModal} />
        </footer>
    );
};

export default Footer;
