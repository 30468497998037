import React from 'react';
import './ParallaxText.css';
import { motion, useMotionValue, useAnimationFrame } from 'framer-motion';

const ParallaxText = ({ children, baseVelocity = 20 }) => {
  const baseX = useMotionValue(0);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    let newX = (baseX.get() + moveBy) % window.innerWidth;
    if (newX < 0) {
      newX += window.innerWidth; // 如果新位置小于0，则将其转移到右侧
    }
    baseX.set(newX);
  });
  

  return (
    <div className="parallax">
      <motion.div className="scroller" style={{ x: baseX }}>
        {Array.from({ length: 12 }).map((_, index) => (
          <div key={index} className="parallax-item">
            {children}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default ParallaxText;
