import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const variants = {
  hidden: { opacity: 0, x: -50, y: 0 },
  visible: { opacity: 1, x: 0, y: 0 },
};

function TimelineEvent({ year, content, image }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // 确定元素是否进入视口

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
      transition={{ type: "spring", stiffness: 100 }}
      className="timeline-event"
    >
      <div className="year">{year}</div>
      <motion.img
        src={image}
        alt={`Event in ${year}`}
        className="event-image"
        whileHover={{ scale: 1.05 }} // 鼠标悬停时放大图片
        transition={{ type: "spring" }}
      />
      <motion.div className="event-content">
        <p>{content}</p>
      </motion.div>
    </motion.div>
  );
}

export default TimelineEvent;
