import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { color, fontFamily } from '@mui/system';

export const ContactUs = () => {
    const form = useRef();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [source, setSource] = useState('');
    const [enquiryType, setEnquiryType] = useState('');
    const [message, setMessage] = useState('');
    const [eventType, setEventType] = useState('');
    const [heardAboutUs, setHeardAboutUs] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');

    const australianPhonePattern = /^(?:\+61|0)[2-478](?:[ -]?[0-9]){8}$/;

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }

        if (!australianPhonePattern.test(phone)) {
            alert("Phone number should be a valid Australian phone number.");
            return;
        }

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_FUNCTION_EMAIL_TEMPLATES,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        ).then((result) => {
            console.log(result.text);
            Swal.fire({
                title: 'Booking Successful!',
                text: 'Your booking has been confirmed.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            setName('');
            setPhone('');
            setEmail('');
            setMessage('');
            setSource('');
            setEnquiryType('');
        }, (error) => {
            console.log(error.text);
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue with your booking. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        });
    };

    return (
        <Box component="form" ref={form} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#638B74', fontSize: '32px' }}>
                Hosting your Golf Day at Yering Meadows
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#2e2e2e' }}>
                The Yering Meadows Golf Club is the ideal venue to host your next corporate golf day. Our professional and friendly staff will tailor an event to ensure a successful and enjoyable day for all involved.
                <br /><br />
                <strong>Course Availability</strong>
                <br />
                The availability of our course and clubhouse facilities can vary depending on the season and other scheduled events but the preferred days are Mondays, Thursdays or Fridays with both morning and afternoon rounds possible.
                <br /><br />
                Shotgun starts are recommended for fields of greater than 80 players up to a maximum of 128. This also guarantees course exclusivity for the duration of your round.
                <br /><br />
                <strong>Golf Lessons</strong>
                <br />
                Before teeing off you might consider providing your group with some specialist golf instruction from a professional. Our professional can run a clinic on any aspect of the game with key areas including short irons, bunker play or putting. Cost = $80.00 per hour.
                <br /><br />
                <strong>Golf</strong>
                <br />
                While social play is allowed it is recommended that Team Ambrose or Individual Stableford competitions be played to enhance the enjoyment of the day. If required we are able to assist with co-ordination and result calculation of the entire event. Nearest the pins on all par 3s, Longest drive on the 18th and Straightest drive on the 10th can all be arranged to add an extra dimension to your day.
                <br /><br />
                <strong>Catering and Refreshments</strong>
                <br />
                A variety of catering options are available from Breakfast and Finger Food through to a more formal a la carte meal with prices starting from $15.00 per head.
                <br /><br />
                All beverages and on course items are charged on consumption.
                <br /><br />
                <strong>Dress regulations</strong>
                <br />
                All players are expected to meet the Club's dress expectations which can be viewed in full;
                <br />
                <a href="/dress-code" target="_blank" rel="noopener noreferrer" style={{ color:'#638B74' }}>View Dress Code</a>
            </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="user_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="user_email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="user_phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="event-type-label">Event Type</InputLabel>
                    <Select
                        labelId="event-type-label"
                        id="event-type"
                        name="event_type"
                        value={eventType}
                        onChange={(e) => setEventType(e.target.value)}
                    >
                        <MenuItem value="wedding">Wedding</MenuItem>
                        <MenuItem value="corporate">Corporate</MenuItem>
                        <MenuItem value="birthday">Birthday</MenuItem>
                        <MenuItem value="high tea">High Tea</MenuItem>
                        <MenuItem value="group dinner">Group Dinner</MenuItem>
                        <MenuItem value="cocktail party">Cocktail Party</MenuItem>
                        <MenuItem value="golf day">Golf Day</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="heard-about-us-label">How did you hear about Yering Meadows?</InputLabel>
                    <Select
                        labelId="heard-about-us-label"
                        id="heard-about-us"
                        name="heard_about_us"
                        value={heardAboutUs}
                        onChange={(e) => setHeardAboutUs(e.target.value)}
                    >
                        <MenuItem value="current member">I'm a current member</MenuItem>
                        <MenuItem value="facebook">Facebook</MenuItem>
                        <MenuItem value="google">Google</MenuItem>
                        <MenuItem value="word of mouth">Word of Mouth</MenuItem>
                        <MenuItem value="road sign">Road Sign</MenuItem>
                        <MenuItem value="website">Website</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    fullWidth
                    id="additionalInfo"
                    label="Anything else you would like to mention?"
                    name="additional_info"
                    multiline
                    rows={4}
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, backgroundColor: '#2D3C34', '&:hover': { backgroundColor: '#92917d' } }}>
                Submit
            </Button>
        </Box>
    );
};
