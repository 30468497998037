import React from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './Booking.css'; 
import { ContactUs } from '../components/GolfForm';
import img1 from '../assets/images/Cafe/ch.jpg';
import img2 from '../assets/images/Cafe/don.jpg';
import img3 from '../assets/images/Cafe/IMG-0668.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

const Booking = () => {

  const slides = [
    { 
        image: img1, 
    },
    { 
        image: img2, 
    },
    { 
        image: img3, 
    },
  ];
  

  return (


    <div>
        <Header />
        <div className='booking-container'>
          <div className='booking-form'>
            <ContactUs/>
          </div>
          <div className='booking-image'>
            {/* Swiper gallery */}
            <Swiper
                modules={[Navigation, Autoplay]}
                navigation
                autoplay={{ delay: 2500 }}
                spaceBetween={50}
                slidesPerView={1}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <img src={slide.image} alt={`Slide ${index}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <Footer />
    </div>
  );
};

export default Booking;
