import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, MenuItem, InputLabel, Select, FormControl, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import './FunctionForm.css';
import { motion } from 'framer-motion';

export const ContactUs = () => {
    const form = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [eventType, setEventType] = useState('');
    const [source, setSource] = useState('');
    const [packageType, setPackageType] = useState('');
    const [drinkDuration, setDrinkDuration] = useState('');
    const [heardAboutUs, setHeardAboutUs] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const australianPhonePattern = /^(?:\+61|0)[2-478](?:[ -]?[0-9]){8}$/;
    const handleSubmit = (e) => {
        e.preventDefault();
        
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        if (!australianPhonePattern.test(phone)) {
            alert("Phone number should be a valid Australian phone number.");
            return;
        }
    
        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_FUNCTION_EMAIL_TEMPLATES,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        ).then((result) => {
            console.log(result.text);
            Swal.fire({
                title: 'Booking Successful!',
                text: 'Your booking has been confirmed.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            setName('');
            setPhone('');
            setEmail('');
            setEventType('');
            setSource('');
            setAdditionalInfo('');
            setPackageType('');
            setDrinkDuration('');
        }, (error) => {
            console.log(error.text);
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue with your booking. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        });
    };

    return (
        <Box sx={{ mt: 1, height: '1100px', overflowY: 'auto',marginTop:'-4%',marginLeft:'-2%',overflowX:'hidden', 
            '@media (max-width: 1100px)': {
            overflowY: 'hidden',
            height: '100%',
            padding: '0 5%'
        }, }}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'GTSD bold', color:'#638B74',fontSize:'32px',paddingTop:'4%' }}>
                Functions & Events
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color:'#2e2e2e',fontSize:'20px' }}>
                Yering Meadows Golf Club will only offer dry hire for certain events.
            </Typography>
            <TableContainer component={Paper} className="function-styled-table" sx={{ width:'95%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Room Size</TableCell>
                            <TableCell>Maximum Capacity</TableCell>
                            <TableCell>Minimum Spend</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Small Vista</TableCell>
                            <TableCell>60 Pax</TableCell>
                            <TableCell>$600</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Large Vista</TableCell>
                            <TableCell>100 Pax</TableCell>
                            <TableCell>$1500</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Whole Function Space</TableCell>
                            <TableCell>200 Pax</TableCell>
                            <TableCell>$3000</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color:'#2e2e2e',fontSize:'20px' }}>
                What is included in the dry hire of the function room?
            </Typography>
            <div className='functionInnerTable'>
                <ul>
                    <li>1. Table</li>
                    <li>2. Chairs</li>
                    <li>3. Chair Covers</li>
                    <li>4. Table Cloths</li>
                </ul>
            </div>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color:'#2e2e2e',fontSize:'20px' }}>  
                Optional items (Price on Request):
            </Typography>
            <div className='functionInnerTable'>
                <ul>
                    <li>Linen Napkins</li>
                    <li>Chair Sashes</li>
                    <li>Dance Floor</li>
                    <li>Stage Hire</li>
                    <li>Audio Visual Equipment Hire</li>
                    <li>Tea & Coffee Station</li>
                    <li>Soft Drink / Juice Station</li>
                    <li>Wine Station</li>
                </ul>
            </div>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color:'#2e2e2e',fontSize:'20px' }}>
                Standard Drinks Package:
            </Typography>
            <TableContainer component={Paper} className="function-styled-table" sx={{ width:'95%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Duration</TableCell>
                            <TableCell>Cost</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>2 Hour</TableCell>
                            <TableCell>$33 ppl inclusive GST</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>3 Hour</TableCell>
                            <TableCell>$44 ppl inclusive GST</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>4 Hour</TableCell>
                            <TableCell>$49.5 ppl inclusive GST</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>5 Hour</TableCell>
                            <TableCell>$55 ppl inclusive GST</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color:'#2e2e2e',fontSize:'20px' }}>
                Drinks Package Includes:
            </Typography>
            <div className='functionInnerTable'>
                <ul>
                    <li>House Sparkling: Brut, Cuvee or Prosecco (Choice of 2)</li>
                    <li>House White: Sauvignon Blanc, Chardonnay or Pinot Grigo(Choice of 2)</li>
                    <li>House Red: Shiraz, Cabernet Sauvignon, Merlot or Pinot Noir(Choice of 2)</li>
                    <li>Tap Beer: Carlton Draught, Great Northern, Hazy Lager or Balter(Choice of 2)</li>
                    <li>Soft Drink</li>
                    <li>Fruit Juice</li>
                    <li>Extra $5 ppl to include Spirits</li>
                </ul>
            </div>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color:'#2e2e2e',fontSize:'20px', pr: '10%' }}>
                Drinks tab and drinks package could be counted towards the minimum spend. There is also
                limited catering package available for certain events, please view the packages available
                below:
            </Typography>
            <motion.button 
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 1 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    className="button" 
                    >
                    <a href="/PDFs/YMGCCELEBRATIONOFLIFEPACKAGE.pdf">Celebration of Life Package</a>
            </motion.button>
            <motion.button 
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 1 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    className="button" 
                    >
                    <a href="/PDFs/YMGCBreakfastPackage.pdf">Breakfast Package</a>
            </motion.button>
            <motion.button 
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 1 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    className="button" 
                    >
                    <a href="/PDFs/FunctionEvents.pdf">Function and Events Package</a>
            </motion.button>
            <Box component="form" ref={form} onSubmit={handleSubmit} noValidate sx={{ mt: 1 , width: '95%', paddingBottom:'1%'}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="user_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="user_email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="user_phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="event-type-label">Event Type</InputLabel>
                    <Select
                        labelId="event-type-label"
                        id="event-type"
                        name="event_type"
                        value={eventType}
                        onChange={(e) => setEventType(e.target.value)}
                    >
                        <MenuItem value="wedding">Wedding</MenuItem>
                        <MenuItem value="corporate">Corporate</MenuItem>
                        <MenuItem value="birthday">Birthday</MenuItem>
                        <MenuItem value="high tea">High Tea</MenuItem>
                        <MenuItem value="group dinner">Group Dinner</MenuItem>
                        <MenuItem value="cocktail party">Cocktail Party</MenuItem>
                        <MenuItem value="golf day">Golf Day</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="heard-about-us-label">How did you hear about Yering Meadows?</InputLabel>
                    <Select
                        labelId="heard-about-us-label"
                        id="heard-about-us"
                        name="heard_about_us"
                        value={heardAboutUs}
                        onChange={(e) => setHeardAboutUs(e.target.value)}
                    >
                        <MenuItem value="current member">I'm a current member</MenuItem>
                        <MenuItem value="facebook">Facebook</MenuItem>
                        <MenuItem value="google">Google</MenuItem>
                        <MenuItem value="word of mouth">Word of Mouth</MenuItem>
                        <MenuItem value="road sign">Road Sign</MenuItem>
                        <MenuItem value="website">Website</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    fullWidth
                    id="additionalInfo"
                    label="Anything else you would like to mention?"
                    name="additional_info"
                    multiline
                    rows={4}
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                />
                <Button type="submit" fullWidth variant="contained"
                sx={{
                    backgroundColor: '#2D3C34', // Change this to your desired color
                    '&:hover': {
                        backgroundColor: '#92917d', // Change this to your desired hover color
                    },
                }}>
                    Submit
                </Button>
            </Box>
        </Box>
    );
};
