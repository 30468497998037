import React from 'react';
import { Typography, Box, Link as MuiLink } from '@mui/material';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const ContactUs = () => {
    return (
        <div>
            <Header />
            <Box sx={{ mt: 10, px: 3, py: 5, backgroundColor: '#F3E8DD' }}>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#638B74', fontSize: '32px' ,paddingTop:'2%' }}>
                    Contact Us
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#2e2e2e' }}>
                    For golf inquiries, please contact our golf shop at:
                    <br />
                    Phone: 03 9738 9001
                    <br />
                    Email: <MuiLink href="mailto:ym.golfshop@yeringmeadows.com.au" sx={{ color: '#638B74' }}>ym.golfshop@yeringmeadows.com.au</MuiLink>
                    <br /><br />
                    For membership inquiries, please contact our Memberships & Administration Officer, Shannon Krause, at:
                    <br />
                    Phone: 03 9738 9000
                    <br />
                    Email: <MuiLink href="mailto:ym.reception@yeringmeadows.com.au" sx={{ color: '#638B74' }}>ym.reception@yeringmeadows.com.au</MuiLink>
                    <br /><br />
                    For function and event inquiries, please contact our Memberships & Administration Officer, Shannon Krause, at:
                    <br />
                    Phone: 03 9738 9000
                    <br />
                    Email: <MuiLink href="mailto:ym.reception@yeringmeadows.com.au" sx={{ color: '#638B74' }}>ym.reception@yeringmeadows.com.au</MuiLink>
                </Typography>
            </Box>
            <Footer />
        </div>
    );
};

export default ContactUs;
