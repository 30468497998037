import React from 'react';
import { motion } from 'framer-motion'; // Removed unused imports: useRef, useEffect, useAnimation
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './VisitorInfo.css';
import VisitorInfoImage from '../assets/images/VisitorInfo/VisitorInfo1.png';
import CourceImage from '../assets/images/VisitorInfo/cource.jpg';
import CafePic from '../assets/images/VisitorInfo/CafePic.jpg';
import ParallaxText from '../components/ParallaxText';
import EastIcon from '@mui/icons-material/East';
import ParallaxBanner from '../components/Banner';

const VisitorInfo = () => {
    return (
        <div>
            <Header />
            <div className="visitor-info-container">
                {/* Left Side with Title and Info */}
                <motion.div className="info-section" 
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}>
                    <p className='welcoming-info'>We welcome visiting golfers</p>
                    <h1>Club</h1>
                    <h2>Information</h2>
                    <p className='p1'>We welcome visiting golfers on the Yering Meadows</p>
                    <p className='p2'>Monday, Tuesday and Friday - morning & afternoon// Thursdays - morning // Sunday - afternoon</p>
                    <p className='p3'>Please make all bookings through the Front desk</p>
                </motion.div>

                {/* Right Side with Image */}
                <motion.div className="imageClubInfo-section" 
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}>
                    <img src={VisitorInfoImage} alt="Golf Course" />
                </motion.div>

                {/* Bottom Right Navigation Aid - Customize as needed */}
                <motion.div className="navigation-aid" 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}>
                </motion.div>
            </div>

            <div className="CourseOverview">
                <p className="Championship">
                <div className="parallax-text-visitor">
                    <ParallaxText>Yering Meadows Golf Club</ParallaxText>
                </div>
                </p>
                <div className="Courseinfo">
                    <p className="Links">Links</p>
                    <img className="CourceImage" src={CourceImage} alt="Course" />
                    <span className="Championship-name">CLUBHOUSE 2024</span>
                </div>
                <div className="mt-8">
                    <div className="CourceOverview">
                        <p className="CourceOverviewTitle">COURSE OVERVIEW</p>
                    </div>
                    <p className="CourceOverviewText">
                        Royal County Down is located in one of the world's most naturally beautiful links settings in the Murlough Nature Reserve.
                    </p>
                </div>
            </div>
            
            <ParallaxBanner/>
            
            <div className="CafeInfo">
                <div className="CafeInfoContainer">
                    <div className="CafeName">
                        <p className="Barname">Murlough bar</p>
                        <div className="OurFaclities">
                            <p>Our</p>
                            <p>Facilities</p>
                        </div>
                        <p className="Dining-room">and dining room</p>
                    </div>
                    <div className="CafeContentContainer">
                        <p className="CafeContentInfo1">
                            After your round, why not enjoy a drink in the Murlough Room, which has views of the 18th green, the 10th tee and the Mountains of Mourne.
                        </p>
                        <p className="CafeContentInfo2">
                            The bar and dining room offer visiting golfers the opportunity to relax in comfort after their round. A menu which includes both light snacks and more substantial meals, all freshly prepared, is available throughout the day, as is the fully licensed bar.
                        </p>
                        <div class="rotated-frame">
                            <div class="rotated-content">
                                <img src={CafePic} alt="Cafe" />
                            </div>
                        </div>
                        <div className="CafeBookingLink">
                            <p>CLUBHOUSE FACILITIES</p>
                            <div className='BookingArrow'>
                                <a href="/booking/table-booking">
                                    <motion.div
                                        initial={{ x: 0 }} 
                                        whileHover={{ x: 10 }}
                                        transition={{ 
                                            duration: 0.3, 
                                            yoyo: Infinity, 
                                            ease: "easeInOut" 
                                        }}
                                    >
                                        <EastIcon/>
                                    </motion.div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default VisitorInfo;