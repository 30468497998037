// src/views/GolfLessons.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import coaches from '../data/coaches'; // Import coaches data
import { motion } from 'framer-motion';
import './GolfLesson.css'; 

const GolfLessons = () => {
    const [topMargin, setTopMargin] = useState('100px');

    useEffect(() => {
        const updateTopMargin = () => {
            const headerHeight = document.querySelector('.header-container').offsetHeight;
            setTopMargin(`${headerHeight}px`);
        };

        updateTopMargin();
        window.addEventListener('resize', updateTopMargin);

        return () => {
            window.removeEventListener('resize', updateTopMargin);
        };
    }, []);

    return (
        <div >
            <Header />
            <Box className="golf-lessons-container" sx={{ mt: topMargin, p: 2, backgroundColor:'#FFFCF9'}}>
                <Typography variant="h2" gutterBottom>
                    Golf Lessons
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', textAlign: 'center' }}>
                        Welcome to our golf lessons page. Here you can find information about our coaches and book lessons.
                    </Typography>
                </Box>
                <Grid container spacing={4} className="coaches-container">
                    {coaches.map((coach, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Link to={`/coach/${coach.id}`} className="coach-link">
                                <Box className="coach-card">
                                    <img src={coach.photo} alt={coach.name} className="coach-photo" />
                                    <Typography variant="h5">{coach.name}</Typography>
                                    <Typography variant="body2">{coach.bio.split('\n')[0]}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
                <Box textAlign="center" mt={4}>
                        <Link to="https://www.picktime.com/YMGC">
                            <motion.button 
                                whileHover={{ scale: 1.03 }}
                                whileTap={{ scale: 1 }}
                                transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                                className="button full-width-button" 
                            >
                                Book Here
                            </motion.button>
                        </Link>
                </Box>
            </Box>
            <Footer />
        </div>
    );
};

export default GolfLessons;
