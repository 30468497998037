import React from 'react';
import './Banner.css';
import GolfHeaderImage from '../assets/images/VisitorInfo/GolfHeaderImage.jpg';

const ParallaxBanner = () => {
    return (
        <div className="parallax-banner" style={{ backgroundImage: `url(${GolfHeaderImage})` }}>
        </div>
    );
};

export default ParallaxBanner;
