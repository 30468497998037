import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

export const ContactUs = () => {
    const form = useRef();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [teeDate, setTeeDate] = useState(null);
    const [teeTime, setTeeTime] = useState('');
    const [crazyGolfCode, setCrazyGolfCode] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        const phonePattern = /^\d+$/;
        if (!phonePattern.test(phone)) {
            alert("Phone number should contain only digits.");
            return;
        }
    
        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_EMAIL_TEMPLATES,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        ).then((result) => {
            console.log(result.text);
            Swal.fire({
                title: 'Booking Successful!',
                text: 'Your booking has been confirmed.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            setFirstName('');
            setLastName('');
            setPhone('');
            setEmail('');
            setTeeDate(null);
            setTeeTime('');
            setCrazyGolfCode('');
        }, (error) => {
            console.log(error.text);
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue with your booking. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        });
    };

    return (
        <Box sx={{ mt: 1 }}>
            <Typography variant="h6" gutterBottom>
                Golf Booking Form
            </Typography>
            <Box component="form" ref={form} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="first_name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="last_name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="user_email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="user_phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Request Tee Date"
                        value={teeDate}
                        onChange={(newValue) => setTeeDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
                    />
                </LocalizationProvider>
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="tee-time-label">Request Tee Time</InputLabel>
                    <Select
                        labelId="tee-time-label"
                        id="tee-time"
                        value={teeTime}
                        onChange={(e) => setTeeTime(e.target.value)}
                    >
                        <MenuItem value="AM">AM</MenuItem>
                        <MenuItem value="PM">PM</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    fullWidth
                    id="crazyGolfCode"
                    label="Crazy Golf Code"
                    name="crazy_golf_code"
                    value={crazyGolfCode}
                    onChange={(e) => setCrazyGolfCode(e.target.value)}
                />
                <Button type="submit" fullWidth variant="contained">
                    Submit
                </Button>
            </Box>
        </Box>
    );
};
