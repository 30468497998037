import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import YouTube from 'react-youtube';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import './GolfCourse.css';

export default function GolfCourse() {
  const nurseryHoles = Array.from({ length: 9 }, (_, index) => `/hole/${index + 1}`);
  const valleyHoles = Array.from({ length: 9 }, (_, index) => `/hole/${index + 10}`);
  const homesteadHoles = Array.from({ length: 9 }, (_, index) => `/hole/${index + 19}`);

  const [topMargin, setTopMargin] = useState('100px');
  const [selectedHole, setSelectedHole] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const updateTopMargin = () => {
      const headerHeight = document.querySelector('.header-container').offsetHeight;
      setTopMargin(`${headerHeight}px`);
    };

    updateTopMargin();
    window.addEventListener('resize', updateTopMargin);

    return () => {
      window.removeEventListener('resize', updateTopMargin);
    };
  }, []);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div>
      <Header />
      <Box className="container mx-auto" sx={{ marginTop: topMargin }}>
        <main className="flex flex-col items-center">
          <Box className="courseOverview" sx={{ padding: '16px', width: '100%', paddingTop: '2%' }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Course Overview
            </Typography>

            <Typography variant="body1" component="p" gutterBottom>
              Located in close proximity to the popular Yarra Valley wine and tourist area, Yering Meadows is a stunning Ross Watson designed championship course, featuring challenges for all levels of golfer. Our 27 hole facility allows us to have three 18 hole combinations. The Nursery course consists of holes 1-18, the Valley course 10-27 and the Homestead course 19-27 followed by holes 1-9. Many variations of the course can be played between course and tee selection.
            </Typography>

            <Box
              component="img"
              sx={{
                width: 'auto',
                height: 'auto',
                marginBottom: '16px',
              }}
              alt="Yering Meadows"
              src="/images/content/coursemap-1.png" 
            />

            <Typography variant="h5" component="h2" id="nursery" gutterBottom>
              Nursery Courses (1-9)
            </Typography>

            <Box className="subpage" sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {nurseryHoles.map((hole, index) => (
                <Link key={index} to={hole} onClick={() => setSelectedHole(index + 1)} style={{ textDecoration: 'none' }}>
                  <IconButton
                    sx={{
                      backgroundColor: selectedHole === index + 1 ? '#2E4D3A' : '#638B74',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: selectedHole === index + 1 ? '#2E4D3A' : '#4F6F5A',
                      },
                      width: 48,
                      height: 48,
                      fontFamily: 'GTSD Italic Light',
                    }}
                  >
                    {index + 1}
                  </IconButton>
                </Link>
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }} className="youtube-container">
              <YouTube videoId="tiHcr_2AaSE" opts={opts} />
            </Box>

            <Typography variant="h5" component="h2" id="valley" gutterBottom sx={{ marginTop: '16px' }}>
              Valley Courses (10-18)
            </Typography>
            <Box className="subpage" sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {valleyHoles.map((hole, index) => (
                <Link key={index} to={hole} onClick={() => setSelectedHole(index + 10)} style={{ textDecoration: 'none' }}>
                  <IconButton
                    sx={{
                      backgroundColor: selectedHole === index + 10 ? '#2E4D3A' : '#638B74',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: selectedHole === index + 10 ? '#2E4D3A' : '#4F6F5A',
                      },
                      width: 48,
                      height: 48,
                      fontFamily: 'GTSD Italic Light',
                    }}
                  >
                    {index + 10}
                  </IconButton>
                </Link>
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }} className="youtube-container">
              <YouTube videoId="yAFM0z3QPrk" opts={opts} />
            </Box>

            <Typography variant="h5" component="h2" id="homestead" gutterBottom sx={{ marginTop: '16px' }}>
              Homestead Courses (19-27)
            </Typography>
            <Box className="subpage" sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {homesteadHoles.map((hole, index) => (
                <Link key={index} to={hole} onClick={() => setSelectedHole(index + 19)} style={{ textDecoration: 'none' }}>
                  <IconButton
                    sx={{
                      backgroundColor: selectedHole === index + 19 ? '#2E4D3A' : '#638B74',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: selectedHole === index + 19 ? '#2E4D3A' : '#4F6F5A',
                      },
                      width: 48,
                      height: 48,
                      fontFamily: 'GTSD Italic Light',
                    }}
                  >
                    {index + 19}
                  </IconButton>
                </Link>
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }} className="youtube-container">
              <YouTube videoId="-s12V9lXKPU" opts={opts} />
            </Box>
          </Box>
        </main>
      </Box>
      <Footer />
    </div>
  );
}
