import React from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './PracticeFacilities.css';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/practicefacilities/PracticeFacilitiesBG.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(numberOfBalls, price) {
  return { numberOfBalls, price };
}

const rows = [
  createData('40 Balls', '$6'),
  createData('80 Balls', '$10'),
];

const PracticeFacilities = () => {
    return (
        <div>
            <Header />
            <div className="practice-facilities-container">
                <motion.div 
                    className="image-section"
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                >
                    <img src={image1} alt="Practice Facilities" />
                </motion.div>
                <motion.div 
                    className="text-section"
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <div className="text-content">
                        <h1>Practice Facilities</h1>
                        <p>Welcome to Yering Meadows Golf Club’s premier practice facility, where golfers of all levels can hone their skills and enjoy the game in a beautiful, serene environment.</p>
                        <h2>Summary</h2>
                        <ul>
                            <li>Full flight golf balls</li>
                            <li>Grass Tees</li>
                            <li>320m Length</li>
                            <li>Two Chipping Greens</li>
                            <li>Two Putting Greens</li>
                            <li>Indoor Simulator with FlightScope</li>
                        </ul>
                        <h2>Details</h2>
                        <p>Our state-of-the-art facility features a 320-meter driving range, perfect for working on your long game and improving your distance. Whether you're a seasoned golfer looking to fine-tune your swing or a beginner aiming to get a feel for the sport, our expansive driving range provides ample space and resources to practice and perfect your technique.</p>
                        <p>For those looking to enhance their short game, Yering Meadows offers two dedicated chipping areas: an 80-meter short game chipping area and a 20-meter short game chipping area. These facilities are designed to help you master the crucial aspects of chipping, from precise distance control to varied shot selection. Practicing in these areas will undoubtedly improve your confidence and performance on the course, ensuring you're prepared for any situation you encounter during your rounds.</p>
                        <p>In addition to our chipping areas, we have two large putting greens available, allowing golfers to practice their putting skills in a realistic setting. Perfect your stroke, work on your alignment, and get a true feel for the greens as you prepare for your next game. Our putting greens are meticulously maintained to provide an excellent practice experience, mirroring the conditions you'll find on our championship course.</p>
                        <p>Yering Meadows Golf Club's practice facility is open to the public seven days a week, ensuring you have the flexibility to practice whenever it suits your schedule. For those looking to practice regardless of weather conditions, we also offer an indoor simulator, providing a cutting-edge virtual golf experience. Whether you're aiming to improve specific aspects of your game or simply want to enjoy some leisure time, our practice facility offers everything you need to elevate your golfing skills. Come visit us and experience the best practice amenities in a welcoming and supportive environment.</p>
                        <h2>Pricing</h2>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Number of Balls</TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.numberOfBalls}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.numberOfBalls}
                                </TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </div>
                </motion.div>
            </div>
            <Footer />
        </div>
    );
};

export default PracticeFacilities;
