import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import './sidebar.css';


const pages = [
  { name: 'Home', link:'/', subpages: [] },
  { 
    name: 'About us', link:'/about-us',
    subpages: [
      { name: 'History', link: '/about-us' },
    //   { name: 'Sponsors / Business Directory', link: '/sponsors' }
    ]
  },
  { name: 'Club News', link: '/club-news', subpages: [] },
  { 
    name: 'Golf', link:'/golf',
    subpages: [
        { name: 'Course Tour', link: '/golf/golfcourse' },
        { name: 'Practice Facilities', link: '/practice-facilities' },
        { name: 'Golf Day', link: '/corporate' },
        // { name: 'Golf lesson', link: '/golf/golf-lesson' },
        { name: 'Dress code', link: '/dress-code' }
    ]
  },
  { 
    name: 'Booking', link:'https://www.yeringmeadows.com.au/guests/bookings/ViewPublicCalendar.msp?booking_resource_id=3000000',
    subpages: [
    //   { name: 'Tee time', link: 'https://www.yeringmeadows.com.au/guests/bookings/ViewPublicCalendar.msp?booking_resource_id=3000000' },
    //   { name: 'Table', link: '/booking/table-booking' },
    //   { name: 'Lesson', link: '/lesson-booking' }
    ]
  },
  { 
    name: 'Membership', link:'/membership',
    subpages: [
    //   { name: 'Categories', link: '/membership-categories' },
    // //   { name: 'Reciprocal Golf Clubs', link: '/reciprocal-clubs' }
    ]
  },
  { 
    name: 'Corporate', link:'/corporate',
    subpages: [
    //   { name: 'Network', link: '/corporate-network' },
    //   { name: 'Package', link: '/corporate-package' }
    ]
  },
  { 
    name: 'Function & Events', link: '/functions-events',
    subpages: [
      { name: 'Wedding', link: '/wedding-events' },
      { name: 'Conference', link: '/conference-events' },
    //   { name: 'Corporate golf day', link: '/corporate-golf' }
    ]
  },
  { name: 'Contact Us', link:'/contact-us', subpages: [] },
//   { name: 'Careers',  subpages: [] }
];



export default function TemporaryDrawer({ state, setState, headerHeight, toggleDrawer }) {
  const [openSubpages, setOpenSubpages] = useState({});

  const handleToggleSubpages = (page, event) => {
    event.stopPropagation(); // Stop event propagation
    setOpenSubpages(prevState => ({
      ...prevState,
      [page]: !prevState[page]
    }));
  };

  const list = (anchor) => (
    <Box
      className="drawer-list"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages.map((page) => (
          <React.Fragment key={page.name}>
            <ListItem disablePadding>
              {page.subpages.length > 0 ? (
                <ListItemButton className="drawer-list-item-button">
                  <ListItemText primary={page.name} onClick={() => window.location.href = page.link} />
                  <IconButton 
                    onClick={(event) => handleToggleSubpages(page.name, event)}
                    size="small"
                    edge="end"
                    aria-label="subpages"
                    style={{ color: '#FFFBB3' }}
                  >
                    {openSubpages[page.name] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemButton>
              ) : (
                <Link to={page.link} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                  <ListItemButton className="drawer-list-item-button">
                    <ListItemText primary={page.name} />
                  </ListItemButton>
                </Link>
              )}
            </ListItem>
            {page.subpages.length > 0 && (
              <Collapse in={openSubpages[page.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.subpages.map((subpage) => (
                    <ListItemButton className="drawer-sublist-item-button" sx={{ pl: 4 }} component={Link} to={subpage.link}>
                      <ListItemText primary={subpage.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        PaperProps={{
          className: 'customDrawerPaper',
          style: { top: headerHeight }
        }}
      >
        {list('left')}
      </Drawer>
    </div>
  );
}


/*
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import './sidebar.css';

const pages = [
  { name: 'Home', link:'/', subpages: [] },
  { 
    name: 'About us', link:'/about-us',
    subpages: [
      { name: 'History', link: '/about-us' },
      { name: 'Sponsors / Business Directory', link: '/sponsors' }
    ]
  },
  { name: 'Club News', link: '/club-news', subpages: [] },
  { 
    name: 'Golf', link:'/golf',
    subpages: [
      { name: 'Course Tour', link: '/course-tour' },
      { name: 'Golf lesson', link: '/golf-lesson' },
      { name: 'Dress code', link: '/dress-code' }
    ]
  },
  { 
    name: 'Booking', link:'/booking',
    subpages: [
      { name: 'Tee time', link: '/tee-time' },
      { name: 'Table', link: '/booking/table-booking' },
      { name: 'Lesson', link: '/lesson-booking' }
    ]
  },
  { 
    name: 'Membership',
    subpages: [
      { name: 'Categories', link: '/membership-categories' },
      { name: 'Reciprocal Golf Clubs', link: '/reciprocal-clubs' }
    ]
  },
  { 
    name: 'Corporate',
    subpages: [
      { name: 'Network', link: '/corporate-network' },
      { name: 'Package', link: '/corporate-package' }
    ]
  },
  { 
    name: 'Function & Events',
    subpages: [
      { name: 'Wedding', link: '/wedding-events' },
      { name: 'Conference', link: '/conference-events' },
      { name: 'Corporate golf day', link: '/corporate-golf' }
    ]
  },
  { name: 'Contact Us', subpages: [] },
  { name: 'Careers', subpages: [] }
];


export default function TemporaryDrawer({ state, setState, headerHeight, toggleDrawer }) {
  const [openSubpages, setOpenSubpages] = useState({});

  const handleToggleSubpages = (page, event) => {
    event.preventDefault(); // Prevent default link behavior
    event.stopPropagation(); // Stop event propagation
    setOpenSubpages(prevState => ({
      ...prevState,
      [page]: !prevState[page]
    }));
  };

  const list = (anchor) => (
    <Box
      className="drawer-list"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages.map((page) => (
          <React.Fragment key={page.name}>
            <ListItem disablePadding>
              <ListItemButton className="drawer-list-item-button">
                {page.subpages.length > 0 ? (
                  <React.Fragment>
                    <ListItemText primary={page.name} onClick={() => window.location.href = page.link} />
                    <IconButton 
                      onClick={(event) => handleToggleSubpages(page.name, event)}
                      size="small"
                      edge="end"
                      aria-label="subpages"
                    >
                      {openSubpages[page.name] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </React.Fragment>
                ) : (
                  <Link to={page.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItemText primary={page.name} />
                  </Link>
                )}
              </ListItemButton>
            </ListItem>
            {page.subpages.length > 0 && (
              <Collapse in={openSubpages[page.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.subpages.map((subpage) => (
                    <ListItemButton className="drawer-sublist-item-button" sx={{ pl: 4 }} component={Link} to={subpage.link}>
                      <ListItemText primary={subpage.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        PaperProps={{
          className: 'customDrawerPaper',
          style: { top: headerHeight }
        }}
      >
        {list('left')}
      </Drawer>
    </div>
  );
}
*/

/*
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import './sidebar.css';


const pages = [
  { name: 'Home', link:'/', subpages: [] },
  { 
    name: 'About us', link:'/about-us',
    subpages: [
      { name: 'History', link: '/about-us' },
      { name: 'Sponsors / Business Directory', link: '/sponsors' }
    ]
  },
  { name: 'Club News', link: '/club-news', subpages: [] },
  { 
    name: 'Golf', link:'/golf',
    subpages: [
      { name: 'Course Tour', link: '/course-tour' },
      { name: 'Golf lesson', link: '/golf-lesson' },
      { name: 'Dress code', link: '/dress-code' }
    ]
  },
  { 
    name: 'Booking', link:'/booking',
    subpages: [
      { name: 'Tee time', link: '/tee-time' },
      { name: 'Table', link: '/booking/table-booking' },
      { name: 'Lesson', link: '/lesson-booking' }
    ]
  },
  { 
    name: 'Membership',
    subpages: [
      { name: 'Categories', link: '/membership-categories' },
      { name: 'Reciprocal Golf Clubs', link: '/reciprocal-clubs' }
    ]
  },
  { 
    name: 'Corporate',
    subpages: [
      { name: 'Network', link: '/corporate-network' },
      { name: 'Package', link: '/corporate-package' }
    ]
  },
  { 
    name: 'Function & Events',
    subpages: [
      { name: 'Wedding', link: '/wedding-events' },
      { name: 'Conference', link: '/conference-events' },
      { name: 'Corporate golf day', link: '/corporate-golf' }
    ]
  },
  { name: 'Contact Us', subpages: [] },
  { name: 'Careers', subpages: [] }
];


export default function TemporaryDrawer({ state, setState, headerHeight, toggleDrawer }) {
  const [openSubpages, setOpenSubpages] = useState({});

  const handleClickSubpages = (page, event) => {
    event.stopPropagation(); // Prevents triggering the link
    setOpenSubpages(prevState => ({
      ...prevState,
      [page]: !prevState[page]
    }));
  };

  const list = (anchor) => (
    <Box
      className="drawer-list"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages.map((page) => (
          <React.Fragment key={page.name}>
            <ListItem disablePadding>
              <ListItemButton className="drawer-list-item-button" component={Link} to={page.link} style={{ flexGrow: 1 }}>
                <ListItemText primary={page.name} />
                {page.subpages.length > 0 && (
                  <IconButton 
                    onClick={(event) => handleClickSubpages(page.name, event)}
                    size="small"
                    edge="end"
                    aria-label="subpages"
                    style={{ color: '#FFFBB3' }} // Set the color of the IconButton
                  >
                    {openSubpages[page.name] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </ListItemButton>
            </ListItem>
            {page.subpages.length > 0 && (
              <Collapse in={openSubpages[page.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.subpages.map((subpage) => (
                    <ListItemButton className="drawer-sublist-item-button" sx={{ pl: 4 }} component={Link} to={subpage.link}>
                      <ListItemText primary={subpage.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        PaperProps={{
          className: 'customDrawerPaper',
          style: { top: headerHeight }
        }}
      >
        {list('left')}
      </Drawer>
    </div>
  );
}
*/
/*
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import './sidebar.css';


const pages = [
  { name: 'Home', link:'/', subpages: [] },
  { 
    name: 'About us', link:'/about-us',
    subpages: [
      { name: 'History', link: '/about-us' },
      { name: 'Sponsors / Business Directory', link: '/sponsors' }
    ]
  },
  { name: 'Club News', link: '/club-news', subpages: [] },
  { 
    name: 'Golf',
    subpages: [
      { name: 'Course Tour', link: '/course-tour' },
      { name: 'Golf lesson', link: '/golf-lesson' },
      { name: 'Dress code', link: '/dress-code' }
    ]
  },
  { 
    name: 'Booking', link:'/booking',
    subpages: [
      { name: 'Tee time', link: '/tee-time' },
      { name: 'Table', link: '/booking/table-booking' },
      { name: 'Lesson', link: '/lesson-booking' }
    ]
  },
  { 
    name: 'Membership',
    subpages: [
      { name: 'Categories', link: '/membership-categories' },
      { name: 'Reciprocal Golf Clubs', link: '/reciprocal-clubs' }
    ]
  },
  { 
    name: 'Corporate',
    subpages: [
      { name: 'Network', link: '/corporate-network' },
      { name: 'Package', link: '/corporate-package' }
    ]
  },
  { 
    name: 'Function & Events',
    subpages: [
      { name: 'Wedding', link: '/wedding-events' },
      { name: 'Conference', link: '/conference-events' },
      { name: 'Corporate golf day', link: '/corporate-golf' }
    ]
  },
  { name: 'Contact Us', subpages: [] },
  { name: 'Careers', subpages: [] }
];



export default function TemporaryDrawer({ state, setState, headerHeight, toggleDrawer }) {
  const [openSubpages, setOpenSubpages] = useState({});

  const handleClick = (page, event) => {
    event.stopPropagation();
    setOpenSubpages(prevState => ({
      ...prevState,
      [page]: !prevState[page]
    }));
  };
  

  

  const list = (anchor) => (
    <Box
      className="drawer-list"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      {pages.map((page) => (
        <React.Fragment key={page.name}>
          <ListItem disablePadding>
            {page.subpages.length > 0 ? (
              // For pages with subpages, clicking the item toggles the subpage list
              <ListItemButton className="drawer-list-item-button" onClick={(event) => handleClick(page.name, event)}>
                <ListItemText primary={page.name} />
                {openSubpages[page.name] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            ) : (
              // For pages without subpages, clicking the item navigates to the link
              <Link to={page.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItemButton className="drawer-list-item-button">
                  <ListItemText primary={page.name} />
                </ListItemButton>
              </Link>
            )}
          </ListItem>
          {page.subpages.length > 0 && (
            <Collapse in={openSubpages[page.name]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {page.subpages.map((subpage) => (
                  <Link to={subpage.link} style={{ textDecoration: 'none', color: 'inherit' }} key={subpage.name}>
                    <ListItemButton className="drawer-sublist-item-button" sx={{ pl: 4 }}>
                      <ListItemText primary={subpage.name} />
                    </ListItemButton>
                  </Link>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
      </List>
    </Box>
  );

  return (
    

    <div>
      <Drawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        // PaperProps={{ className: 'customDrawerPaper' }}
        // modify first time 
        PaperProps={{
          className: 'customDrawerPaper',
          style: { top: headerHeight } // Adjust top position based on header height
        }}
        
      >
        {list('left')}
      </Drawer>
    </div>
  );
}
*/