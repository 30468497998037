import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, MenuItem, InputLabel, Select, FormControl, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import emailjs from '@emailjs/browser';
import { color } from 'framer-motion';
import menuPdf from '../assets/menu/Terrace-Cafe-Menu-23082023.pdf';
import Swal from 'sweetalert2';



export const ContactUs = () => {
    const form = useRef();
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [seats, setSeats] = useState('');
    // Add state for other form fields
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        


        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        // Phone number validation (only digits)
        const phonePattern = /^\d+$/;
        if (!phonePattern.test(phone)) {
            alert("Phone number should contain only digits.");
            return;
        }
    

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_EMAIL_TEMPLATES,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        ).then((result) => {
            console.log(result.text);
            // 表单提交成功后，显示预订成功的 SweetAlert 弹窗
            Swal.fire({
                title: 'Booking Successful!',
                text: 'Your booking has been confirmed.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            // 可以在这里重置表单状态
            setDate(null);
            setTime(null);
            setSeats('');
            setName('');
            setPhone('');
            setEmail('');
            setMessage('');
            // ... 重置其他状态 ...
        }, (error) => {
            console.log(error.text);
            // 表单提交失败，显示错误的 SweetAlert 弹窗
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue with your booking. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        });
    };

    const formatDate = (date) => {
        return date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : '';
    };

    // Function to format time to HH:MM
    const formatTime = (time) => {
        return time ? time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : '';
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>

            {/* Introduction Section */}
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'GTSD Bold', fontSize: '30px' , color:'#598971'}}>
                CLUB HOUSE TRADING HOURS
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', fontSize: '20px',color:'#2e2e2e' }}>
                Members Café<br/>
                7:00 – 18:00 (Wednesdays, Saturdays & Sundays)<br/>
                8:00 – 17:00 (Mondays, Tuesdays, Thursdays & Fridays)<br/><br/>
                    
                Terrace Café Restaurant<br/>
                Weekly Wednesday to Sunday – 11am to 3pm<br/>
                Seniors Special 2 Course Lunch @ $28pp, Available Wednesday – Sunday
            </Typography>

            {/* Menu Button */}
            <Button
                variant="contained"
                style={{ backgroundColor: '#598971', color: '#FFF', '&:hover': { backgroundColor: '#2D3C34' } }}
                href={menuPdf}
                target="_blank"
                sx={{ mb: 2 }}
            >
                View Menu
            </Button>

            <Box component="form" ref={form} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="user_name"
                    autoComplete="name"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="user_email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="user_phone"
                    autoComplete="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <DatePicker
                    label="Date (Required)"
                    value={date}
                    name="date"
                    onChange={setDate}
                    renderInput={(params) => <TextField {...params} required fullWidth />}
                />
                <input type="hidden" name="date" value={formatDate(date)} />
                <TimePicker
                    label="Time (Required)"
                    value={time}
                    name="time"
                    onChange={setTime}
                    renderInput={(params) => <TextField {...params} required fullWidth />}
                />
                <input type="hidden" name="time" value={formatTime(time)} />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="seats-label">Seats (Required)</InputLabel>
                    <Select
                        labelId="seats-label"
                        id="seats"
                        name="seats"
                        value={seats}
                        label="Seats (Required)"
                        onChange={(e) => setSeats(e.target.value)}
                        required
                    >
                        {[1, 2, 3, 4, 5, 6].map((seat) => (
                            <MenuItem key={seat} value={seat}>
                                {seat}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    fullWidth
                    id="message"
                    label="Any specific requirements about seats and meals?"
                    name="message"
                    autoComplete="off"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                /> 
                <Button type="submit" fullWidth variant="contained"  
                sx={{
                    backgroundColor: '#598971', 
                    color: '#fff',
                    '&:hover': {
                    backgroundColor: '#2D3C34', 
                    },
                }}>
                    Submit
                </Button>
            </Box>
        </LocalizationProvider>
    );
};
